import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { size } from 'lodash'
import {
  Button,
  CircularProgress,
  Grid,
  TablePagination,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fetchCurrentUser } from 'actions/userActions'
import {
  AlertTable,
  ConfirmationDialog,
  ErrorMessage,
  FormContainer,
  Gallery,
  InteractiveTutorial,
  Layout,
  ListingTable,
  OffersTable,
  OnBoard,
  ReviewsTable,
  SEO,
  SkeletonGallery,
  SkeletonUserProfileInfo,
  UpdatePasswordForm,
  UserProfileInfo,
} from 'components'
import {
  ERROR_FETCHING_ALERTS,
  ERROR_UPDATING_ALERT_LIST,
  STRIPE_ONBOARDED_SUCCESS,
} from 'constants/alertConstants'
import { NOT_FOUND_ENDPOINT, SIGNUP_ENDPOINT } from 'constants/apiUrls'
import {
  MANAGE_STORE,
  TUTORIAL_NAMES,
} from 'constants/interactiveTutorialConstants'
import {
  DEFAULT_LISTING_LIMITS,
  ERROR_FETCHING_LISTINGS,
  ERROR_UPDATING_LISTING,
  LISTING_STATUS,
} from 'constants/listingConstants'
import {
  DEFAULT_LIMITS,
  ERROR_FETCHING_OFFERS,
  OFFER_STATUS,
} from 'constants/offerConstants'
import { ERROR_FETCHING_STORE } from 'constants/storeConstants'
import {
  ERROR_CHECKING_STRIPE_ONBOARD_STATUS,
  SUCCESSFUL,
} from 'constants/stripeConstants'
import { USER_MENU } from 'constants/userConstants'
import {
  createAlert,
  fetchAlerts,
  markAlertAsRead,
} from 'services/AlertService'
import { fetchDesigns } from 'services/DesignService'
import { addPaymentInfo } from 'services/GoogleAnalyticsService'
import {
  getListing,
  getListingsByIds,
  updateListingStatus,
} from 'services/ListingService'
import { sendMailToMaker } from 'services/MailService'
import { getOffersByMaker } from 'services/OfferService'
import { getStoreItems } from 'services/StoreService'
import { getStripeAccount } from 'services/StripeService'
import { getUserById, logout, updateStripeUserInfo } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.text.gray,
    '&:hover': {
      color: theme.palette.text.white,
      backgroundColor: theme.palette.background.default,
      opacity: 1,
    },
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginRight: '12px',
  },
  container: {
    padding: '32px',
  },
  outerContainer: {
    position: 'relative',
  },
  circularProgress: {
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      zIndex: '3',
      width: '40px',
      height: '40px',
    },
    position: 'absolute',
    left: 'calc(50% - 40px)',
    top: 'calc(50% - 40px)',
  },
  dynamicContainer: {
    backgroundColor: theme.palette.background.white,
    minHeight: '550px',
    height: '90vh',
    overflowY: 'hidden',
    boxShadow: `0px 24px 27px ${theme.palette.border.shadow}`,
    paddingTop: 'unset',
    '& .MuiGrid-root .MuiGrid-root': {
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up(1925)]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      [theme.breakpoints.up(2325)]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
      [theme.breakpoints.up(2725)]: {
        gridTemplateColumns: 'repeat(6, 1fr)',
      },
    },
    '& .designs .MuiCardActions-root': {
      padding: '0px 8px',
    },
    '& .designs .MuiGrid-root': {
      minHeight: '480px',
      marginTop: '0px',
      height: '82vh',
      overflowY: 'auto',
      '& .MuiGrid-root': {
        width: '100%',
        '& .MuiPaper-root.MuiCard-root': {
          marginLeft: 'auto',
          marginRight: 'auto',
          '@media (max-width:280px)': {
            minWidth: '200px',
          },
        },
      },
    },
    '& .option-offers': {
      '& .MuiButtonBase-root:hover': {
        backgroundColor: theme.palette.background.white,
        color: theme.palette.text.primary,
      },
    },
  },
  selectedOption: {
    color: theme.palette.text.primary,
    borderBottomColor: theme.palette.border.blue,
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderRadius: 'unset',
  },
  footerStyles: {
    '& .MuiToolbar-root .MuiTypography-root, .MuiInputBase-root .MuiSelect-root': {
      fontWeight: 'bold',
    },
    '& .MuiToolbar-root .MuiTypography-root': {
      pointerEvents: 'none',
      '&focused': {
        backgroundColor: theme.palette.background.white,
      },
    },
    '& .MuiToolbar-root .MuiInputBase-root': {
      pointerEvents: 'none',
      '&focused': {
        backgroundColor: theme.palette.background.white,
      },
    },
    '& .MuiToolbar-root .MuiInputBase-root .MuiSvgIcon-root': {
      display: 'none',
    },
  },
  fullWidth: {
    width: '100%',
    marginBottom: '20px',
  },
  progress: {
    position: 'relative',
    top: '50%',
    left: '50%',
    zIndex: '10000000',
  },
  disableTable: {
    pointerEvents: 'none',
    marginTop: '-40px',
  },
  upperCase: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}))

const modelsDefaultState = {
  models: [],
  fetched: false,
}

const storeDefaultState = {
  items: [],
  fetched: false,
}

const DESIGNS_LIMIT = 15
const {
  APPROVED,
  AWAITING_APPROVAL,
  DELIVERED,
  IN_PROGRESS,
  OFFER_ACCEPTED,
  OFFER_COMPLETED,
  OFFER_PENDING,
  SHIPPED,
  SUBMITTED,
} = LISTING_STATUS

const activeStatuses = [
  OFFER_STATUS.ACCEPTED,
  OFFER_STATUS.IN_PROGRESS,
  OFFER_STATUS.PENDING,
  OFFER_STATUS.SUBMITTED,
]
const closedStatuses = [
  OFFER_STATUS.COMPLETED,
  OFFER_STATUS.EXPIRED,
  OFFER_STATUS.LISTING_DELIVERED,
  OFFER_STATUS.REJECTED,
]

const ACTIVE = 'ACTIVE'
const CLOSED = 'CLOSED'

const populateListings = async offers => {
  let offerListing = []
  try {
    const listIds = offers.map(offer => offer.listingId)
    const listingsList = await getListingsByIds(listIds)
    listingsList.listings.forEach(listing => {
      const offer = offers.filter(item => item.listingId === listing.id)[0]
      offerListing = [
        ...offerListing,
        {
          ...offer,
          listing,
        },
      ]
    })
    return offerListing
  } catch (error) {
    throw error
  }
}

const Profile = ({ location }) => {
  const {
    isFetchingUser: isFetchingLoggedUser,
    user: loggedUser,
  } = useSelector(state => state.userState)
  const classes = useStyles()
  const { state = {}, pathname } = location
  const collapsedListing = state ? state.collapsedListing : ''
  const [alertStatus, setAlertStatus] = useState({
    alertList: [],
    isFetched: false,
    lastAlert: null,
  })
  const [designsPage, setDesignsPage] = useState(0)
  const [displayPrivateInfo, setDisplayPrivateInfo] = useState(false)
  const [displayRegisterModal, setDisplayRegisterModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasNext, setHasNext] = useState(true)
  const [hasNextDesigns, setHasNextDesigns] = useState(true)
  const [hasNextItems, setHasNextItems] = useState(true)
  const [hasNextOffers, setHasNextOffers] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isFetchingProfileUser, setIsFetchingProfileUser] = useState(false)
  const [latestDesigns, setLatestDesigns] = useState([])
  const [latestElements, setLatestElements] = useState([])
  const [latestItem, setLatestItem] = useState([])
  const [latestOffers, setLatestOffers] = useState([])
  const [listings, setListings] = useState([])
  const [listingsPage, setListingsPage] = useState(0)
  const [listingLimit, setListingsLimit] = useState(DEFAULT_LISTING_LIMITS[0])
  const [makerChecked, setMakerChecked] = useState(false)
  const [modelsState, setModelsState] = useState(modelsDefaultState)
  const [offersState, setOffersState] = useState(ACTIVE)
  const [offersLimit, setOffersLimit] = useState(DEFAULT_LIMITS[0])
  const [offersList, setOffersList] = useState([])
  const [profileUser, setProfileUser] = useState(null)
  const [storeState, setStoreState] = useState(storeDefaultState)
  const [storePage, setStorePage] = useState(0)
  const [selectedOption, setSelectedOption] = useState({
    designs: false,
    mylistings: false,
    offers: false,
    reviews: false,
    settings: false,
    store: false,
  })
  const [updatingAlerts, setUpdatingAlerts] = useState(false)

  useEffect(() => {
    const profileUid = pathname.split('/').pop()
    const getProfileUser = async () => {
      setIsFetchingProfileUser(true)
      if (profileUid === loggedUser.uid) {
        setDisplayPrivateInfo(true)
        setProfileUser(loggedUser)
      } else {
        setDisplayPrivateInfo(false)
        try {
          const user = await getUserById(profileUid)
          setProfileUser(user)
        } catch (error) {
          navigate(NOT_FOUND_ENDPOINT)
        }
      }
      setModelsState(modelsDefaultState)
      setStoreState(storeDefaultState)
      setListings([])
      setLatestElements([])
      setIsFetchingProfileUser(false)
    }

    if (
      !isFetchingLoggedUser &&
      loggedUser &&
      (!profileUser || (profileUser && profileUid !== profileUser.uid))
    ) {
      getProfileUser()
    }
  }, [isFetchingLoggedUser, loggedUser, pathname, profileUser])

  useEffect(() => {
    const initModels = async () => {
      const { lastElement, designs } = await fetchDesigns(
        profileUser.uid,
        DESIGNS_LIMIT,
        null,
        displayPrivateInfo
      )
      setModelsState({
        models: designs,
        fetched: true,
      })
      setHasNextDesigns(size(designs) === DESIGNS_LIMIT)
      setLatestDesigns([lastElement])
    }

    if (!modelsState.fetched && profileUser && !size(modelsState.models)) {
      initModels()
    }
  }, [displayPrivateInfo, profileUser, modelsState])

  useEffect(() => {
    if (state && state.option) {
      handleSelectMenu(state.option)
    } else {
      if (profileUser && profileUser.isMaker) {
        handleSelectMenu(USER_MENU.STORE)
      } else {
        handleSelectMenu(USER_MENU.DESIGNS)
      }
    }
  }, [state, profileUser])

  useEffect(() => {
    const fetchStoreModels = async () => {
      try {
        const { lastElement, items } = await getStoreItems(
          profileUser.uid,
          DESIGNS_LIMIT,
          null
        )
        setStoreState({ fetched: true, items: items })
        setLatestItem([lastElement])
        setHasNextItems(size(items) === DESIGNS_LIMIT)
      } catch (error) {
        setErrorMessage(ERROR_FETCHING_STORE)
      }
    }
    if (
      profileUser &&
      profileUser.isMaker &&
      !storeState.fetched &&
      !size(storeState.items)
    ) {
      fetchStoreModels()
    }
  }, [profileUser, storeState])

  useEffect(() => {
    const verifyStripeOnboard = async () => {
      setErrorMessage('')
      try {
        setMakerChecked(true)
        const response = await getStripeAccount(profileUser.stripe_account_id)
        const { data: stripeAccount } = response
        if (stripeAccount.details_submitted) {
          await updateStripeUserInfo(profileUser.uid, {
            isMaker: true,
            stripe_onboarding_status: SUCCESSFUL,
          })
          fetchCurrentUser()
          addPaymentInfo('STRIPE')
          await sendMailToMaker(profileUser.email, profileUser.first_name)
          await createAlert(null, profileUser, STRIPE_ONBOARDED_SUCCESS, null)
          setUpdatingAlerts(true)
          const { alerts, lastAlert } = await fetchAlerts(profileUser.uid)
          setAlertStatus({
            alertList: alerts,
            isFetched: true,
            lastAlert: lastAlert,
          })
          setUpdatingAlerts(false)
        }
      } catch (error) {
        setErrorMessage(ERROR_CHECKING_STRIPE_ONBOARD_STATUS)
      }
    }

    if (
      !makerChecked &&
      profileUser &&
      displayPrivateInfo &&
      profileUser.stripe_account_id &&
      !profileUser.isMaker
    ) {
      verifyStripeOnboard()
    }
  }, [alertStatus, displayPrivateInfo, profileUser, makerChecked])

  const fetchOffers = async (
    limit = DEFAULT_LIMITS[0],
    lastElement,
    newOffersState = offersState
  ) => {
    setIsFetching(true)
    setOffersList([])
    let updated = false
    setErrorMessage('')
    setOffersLimit(limit)
    try {
      const { lastElement: newLastElement, offers } = await getOffersByMaker(
        profileUser.uid,
        limit,
        lastElement,
        newOffersState === ACTIVE ? activeStatuses : closedStatuses
      )
      if (size(offers) > 0) {
        updated = true
        setHasNextOffers(size(offers) === limit)
        const offerListing = await populateListings(offers)
        setOffersList(offerListing)
        if (lastElement) {
          setLatestOffers([...latestOffers, newLastElement])
        } else {
          setLatestOffers([newLastElement])
        }
      } else {
        if (!lastElement) {
          setOffersList([])
          setLatestOffers([])
        }
        setHasNextOffers(false)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_OFFERS)
    }
    setIsFetching(false)
    return updated
  }

  const nextOffersPage = async (page, limit) => {
    const lastElement = latestOffers[page - 1]
    return fetchOffers(limit, lastElement, offersState)
  }

  const prevOffersPage = async (page, limit) => {
    const lastElement = page > 0 ? latestOffers[page - 1] : null
    latestOffers.pop()
    latestOffers.pop()
    setLatestOffers(latestOffers)
    fetchOffers(limit, lastElement, offersState)
  }

  const handleSelectMenu = selected => {
    setSelectedOption({
      designs: selected === USER_MENU.DESIGNS,
      mylistings: selected === USER_MENU.LIST,
      offers: selected === USER_MENU.OFFERS,
      reviews: selected === USER_MENU.REVIEWS,
      settings: selected === USER_MENU.SETTINGS,
      store: selected === USER_MENU.STORE,
    })
    if (selected === USER_MENU.LOGOUT) {
      logout()
    }
  }

  useEffect(() => {
    const getInitialListings = async () => {
      setIsFetching(true)
      setErrorMessage('')
      try {
        const { lastElement, listings } = await getListing(
          profileUser.uid,
          DEFAULT_LISTING_LIMITS[0],
          null,
          [
            APPROVED,
            AWAITING_APPROVAL,
            IN_PROGRESS,
            OFFER_ACCEPTED,
            OFFER_COMPLETED,
            OFFER_PENDING,
            SHIPPED,
            SUBMITTED,
          ]
        )
        setHasNext(size(listings) === DEFAULT_LISTING_LIMITS[0])
        setListings(listings)
        setLatestElements([lastElement])
      } catch (error) {
        setErrorMessage(ERROR_FETCHING_LISTINGS)
      }
      setIsFetching(false)
    }
    if (
      profileUser &&
      displayPrivateInfo &&
      !size(listings) &&
      !size(latestElements)
    ) {
      getInitialListings()
    }
  }, [displayPrivateInfo, listings, latestElements, profileUser])

  useEffect(() => {
    const getInitialOffers = async () => {
      setIsFetching(true)
      const limit = DEFAULT_LIMITS[2]
      setErrorMessage('')
      try {
        const { lastElement, offers } = await getOffersByMaker(
          profileUser.uid,
          DEFAULT_LIMITS[2],
          null,
          activeStatuses
        )
        setHasNextOffers(size(offers) === limit)
        const offerListing = await populateListings(offers)
        setOffersList(offerListing)
        setLatestOffers([lastElement])
      } catch (error) {
        setErrorMessage(ERROR_FETCHING_OFFERS)
      }
      setIsFetching(false)
    }
    if (
      displayPrivateInfo &&
      profileUser &&
      !size(offersList) &&
      !size(latestOffers) &&
      offersState === ACTIVE
    ) {
      getInitialOffers()
    }
  }, [displayPrivateInfo, profileUser, offersList, latestOffers, offersState])

  const handleRequestAlerts = async () => {
    const { alerts, lastAlert } = await fetchAlerts(
      profileUser.uid,
      alertStatus.lastAlert
    )
    if (lastAlert) {
      setAlertStatus({
        alertList: [...alertStatus.alertList, ...alerts],
        isFetched: true,
        lastAlert,
      })
    }
  }

  useEffect(() => {
    const getAlerts = async () => {
      setErrorMessage('')
      try {
        setUpdatingAlerts(true)
        const { alerts, lastAlert } = await fetchAlerts(profileUser.uid)
        setAlertStatus({
          alertList: alerts,
          isFetched: true,
          lastAlert: lastAlert,
        })
        setUpdatingAlerts(false)
      } catch (error) {
        setErrorMessage(ERROR_FETCHING_ALERTS)
      }
    }

    if (displayPrivateInfo && profileUser && !alertStatus.isFetched) {
      getAlerts()
    }
  }, [alertStatus, displayPrivateInfo, profileUser])

  const fetchListings = async (
    limit = DEFAULT_LISTING_LIMITS[0],
    lastElement,
    newPage
  ) => {
    let hasUpdated = false
    setIsFetching(true)
    setErrorMessage('')
    setListingsLimit(limit)
    try {
      const {
        lastElement: newLastElement,
        listings,
      } = await getListing(loggedUser.uid, limit, lastElement, [
        APPROVED,
        AWAITING_APPROVAL,
        IN_PROGRESS,
        OFFER_ACCEPTED,
        OFFER_COMPLETED,
        OFFER_PENDING,
        SHIPPED,
        SUBMITTED,
      ])
      if (size(listings) > 0) {
        hasUpdated = true
        setListingsPage(newPage)
        setHasNext(size(listings) === limit)
        setListings(listings)
        if (lastElement) {
          setLatestElements([...latestElements, newLastElement])
        } else {
          setLatestElements([newLastElement])
        }
      } else {
        setHasNext(false)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_LISTINGS)
    }
    setIsFetching(false)
    return hasUpdated
  }

  const nextPage = async (page, limit) => {
    const lastElement = latestElements[page - 1]
    return fetchListings(limit, lastElement, page)
  }

  const prevPage = async (page, limit) => {
    const lastElement = page > 0 ? latestElements[page - 1] : null
    latestElements.pop()
    latestElements.pop()
    setLatestElements(latestElements)
    fetchListings(limit, lastElement, page)
  }

  const getDesigns = async lastElement => {
    let hasUpdated = false
    setIsFetching(true)
    setErrorMessage('')
    try {
      const { lastElement: newLastElement, designs } = await fetchDesigns(
        loggedUser.uid,
        DESIGNS_LIMIT,
        lastElement
      )
      if (size(designs) > 0) {
        hasUpdated = true
        setHasNextDesigns(size(designs) === DESIGNS_LIMIT)
        setModelsState({
          models: designs,
          fetched: true,
        })
        if (lastElement) {
          setLatestDesigns([...latestDesigns, newLastElement])
        } else {
          setLatestDesigns([newLastElement])
        }
      } else {
        setHasNextDesigns(false)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_LISTINGS)
    }
    setIsFetching(false)
    return hasUpdated
  }

  const getStore = async lastElement => {
    let hasUpdated = false
    setIsFetching(true)
    setErrorMessage('')
    try {
      const { lastElement: newLastElement, items } = await getStoreItems(
        profileUser.uid,
        DESIGNS_LIMIT,
        lastElement
      )
      if (size(items) > 0) {
        hasUpdated = true
        setHasNextItems(size(items) === DESIGNS_LIMIT)
        setStoreState({
          items: items,
          fetched: true,
        })
        if (lastElement) {
          setLatestItem([...latestDesigns, newLastElement])
        } else {
          setLatestItem([newLastElement])
        }
      } else {
        setHasNextItems(false)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_STORE)
    }
    setIsFetching(false)
    return hasUpdated
  }

  const nextDesingsPage = async page => {
    const lastElement = latestDesigns[page - 1]
    return getDesigns(lastElement)
  }

  const nextStorePage = async page => {
    const lastItem = latestItem[page - 1]
    return getStore(lastItem)
  }

  const prevDesignsPage = async page => {
    const lastElement = page > 0 ? latestDesigns[page - 1] : null
    latestDesigns.pop()
    latestDesigns.pop()
    setLatestDesigns(latestDesigns)
    getDesigns(lastElement)
  }

  const prevStorePage = async page => {
    const lastElement = page > 0 ? latestItem[page - 1] : null
    latestItem.pop()
    latestItem.pop()
    setLatestItem(latestItem)
    await getStore(lastElement)
  }

  const refreshCurrentListingsPage = async () => {
    latestElements.pop()
    setLatestElements(latestElements)
    const lastElement =
      listingsPage > 0 ? latestElements[listingsPage - 1] : null
    fetchListings(listingLimit, lastElement, listingsPage)
  }

  const handleChangePage = async (event, newPage) => {
    let response = true
    if (newPage > designsPage) {
      response = await nextDesingsPage(newPage)
    } else {
      await prevDesignsPage(newPage)
    }
    if (response) {
      setDesignsPage(newPage)
    }
  }

  const handleStoreChangePage = async (event, newPage) => {
    let response = true
    if (newPage > storePage) {
      response = await nextStorePage(newPage)
    } else {
      await prevStorePage(newPage)
    }
    if (response) {
      setStorePage(newPage)
    }
  }

  const updateListing = async listingId => {
    try {
      setErrorMessage('')
      await updateListingStatus(listingId, DELIVERED)
      if (size(listings) > 1 || listingsPage === 0) {
        await refreshCurrentListingsPage()
      } else {
        prevPage(listingsPage - 1, listingLimit)
      }
    } catch (error) {
      setErrorMessage(ERROR_UPDATING_LISTING)
    }
  }

  const forms = [
    {
      label: 'Edit Personal Information',
      component: <OnBoard editing={true} />,
    },
    {
      label: 'Update Password',
      component: <UpdatePasswordForm />,
    },
  ]

  const updateOffersState = async newOfferState => {
    if (newOfferState !== offersState) {
      setOffersState(newOfferState)
      await fetchOffers(offersLimit, null, newOfferState)
    }
  }

  const readAlert = async alertId => {
    setErrorMessage('')
    try {
      setUpdatingAlerts(true)
      await markAlertAsRead(alertId)
      const newAlertList = alertStatus.alertList.filter(
        item => item.uid !== alertId
      )
      setAlertStatus({
        ...alertStatus,
        alertList: newAlertList,
      })
      setUpdatingAlerts(false)
    } catch (error) {
      setErrorMessage(ERROR_UPDATING_ALERT_LIST)
    }
  }

  const handleReject = offer => {
    const offers = [...offersList]
    const index = offersList.indexOf(offer)
    if (index > -1) {
      offers.splice(index, 1)
      setOffersList(offers)
    }
  }

  return (
    <Layout>
      <SEO title="Profile" />
      {!isFetchingProfileUser ? (
        <div>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <UserProfileInfo
                isPublic={!displayPrivateInfo}
                isMaker={profileUser && profileUser.isMaker}
                initialOption={selectedOption}
                user={profileUser ? profileUser : {}}
                onSelectMenu={handleSelectMenu}
              />
            </Grid>
            <Grid
              className={classes.container}
              container
              item
              xs={12}
              sm={8}
              md={9}
            >
              {displayPrivateInfo && (
                <Grid container item xs={12}>
                  {updatingAlerts && (
                    <CircularProgress className={classes.progress} />
                  )}
                  <div
                    className={clsx(
                      {
                        [classes.disableTable]: updatingAlerts,
                      },
                      classes.fullWidth
                    )}
                    id="alert-table"
                  >
                    <AlertTable
                      alertList={alertStatus.alertList}
                      markAlertAsRead={readAlert}
                      onRequestAlerts={handleRequestAlerts}
                    />
                  </div>
                </Grid>
              )}
              <Grid
                className={classes.dynamicContainer}
                id="my-designs"
                item
                xs={12}
              >
                <ErrorMessage message={errorMessage} />
                {selectedOption.designs && (
                  <div className="designs">
                    {isFetching ? (
                      <SkeletonGallery className={classes.formContainer} />
                    ) : (
                      <Gallery
                        className={classes.height}
                        displayPrivateInfo={displayPrivateInfo}
                        models={modelsState.models}
                      />
                    )}
                    {size(modelsState.models) > 0 && (
                      <TablePagination
                        className={classes.footerStyles}
                        component="div"
                        count={-1}
                        labelDisplayedRows={({ from, to }) => `${from}-${to}`}
                        labelRowsPerPage="Elements to display per page:"
                        nextIconButtonProps={{ disabled: !hasNextDesigns }}
                        page={designsPage}
                        rowsPerPage={DESIGNS_LIMIT}
                        rowsPerPageOptions={[DESIGNS_LIMIT]}
                        onChangePage={handleChangePage}
                      />
                    )}
                  </div>
                )}
                {selectedOption.mylistings && displayPrivateInfo && (
                  <ListingTable
                    collapsedListing={collapsedListing}
                    acknowledgeReceipt={updateListing}
                    currentPage={listingsPage}
                    currentUser={loggedUser}
                    fetchListings={fetchListings}
                    hasNext={hasNext}
                    isFetching={isFetching}
                    listings={listings}
                    nextPage={nextPage}
                    prevPage={prevPage}
                    refreshCurrentListingsPage={refreshCurrentListingsPage}
                    showDetails={true}
                    showOffersModal={true}
                  />
                )}
                {selectedOption.offers && displayPrivateInfo && (
                  <>
                    <div className="option-offers">
                      <Button
                        className={clsx(classes.button, {
                          [classes.selectedOption]: offersState === ACTIVE,
                        })}
                        onClick={() => updateOffersState(ACTIVE)}
                      >
                        Active Offers
                      </Button>
                      <Button
                        className={clsx(classes.button, {
                          [classes.selectedOption]: offersState === CLOSED,
                        })}
                        onClick={() => updateOffersState(CLOSED)}
                      >
                        Closed Offers
                      </Button>
                    </div>
                    <div className={classes.outerContainer}>
                      <div className={classes.circularProgress}>
                        {isFetching && <CircularProgress size={40} />}
                      </div>
                      <OffersTable
                        fetchOffers={fetchOffers}
                        hasNext={hasNextOffers}
                        isFetching={isFetching}
                        initRowsPerPage={DEFAULT_LIMITS[2]}
                        isMaker={true}
                        offersList={offersList}
                        offersState={offersState}
                        needLoad={false}
                        nextPage={nextOffersPage}
                        prevPage={prevOffersPage}
                        rejectOffer={handleReject}
                      />
                    </div>
                  </>
                )}
                {selectedOption.store && (
                  <div className="designs">
                    {isFetching ? (
                      <SkeletonGallery className={classes.formContainer} />
                    ) : (
                      <>
                        <Gallery
                          addPrice={true}
                          className={classes.height}
                          displayPrivateInfo={displayPrivateInfo}
                          models={storeState.items}
                          showRegisterRedirect={loggedUser.isAnonymous}
                          onClickShowRegisterRedirect={() =>
                            setDisplayRegisterModal(true)
                          }
                        />
                        <ConfirmationDialog
                          open={displayRegisterModal}
                          title="Do you want to build this? Please login/sign up"
                          confirmLabel="login/sign up"
                          cancelLabel="Cancel"
                          onAccept={() => navigate(SIGNUP_ENDPOINT)}
                          onCancel={() => setDisplayRegisterModal(false)}
                        />
                      </>
                    )}
                    <TablePagination
                      className={classes.footerStyles}
                      component="div"
                      count={-1}
                      nextIconButtonProps={{ disabled: !hasNextItems }}
                      labelDisplayedRows={({ from, to }) => `${from}-${to}`}
                      labelRowsPerPage="Elements to display per page:"
                      page={storePage}
                      onChangePage={handleStoreChangePage}
                      rowsPerPage={DESIGNS_LIMIT}
                      rowsPerPageOptions={[DESIGNS_LIMIT]}
                    />
                  </div>
                )}
                {selectedOption.reviews && (
                  <ReviewsTable makerId={profileUser.uid} />
                )}
                {selectedOption.settings && (
                  <div className={classes.formContent}>
                    <FormContainer
                      forms={forms}
                      className={classes.formContainer}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Grid container>
          <Grid item xs={12} sm={4} md={3}>
            <SkeletonUserProfileInfo />
          </Grid>
        </Grid>
      )}
      {loggedUser &&
        !loggedUser.isAnonymous &&
        loggedUser.tutorial_state &&
        !loggedUser.tutorial_state.manageStore &&
        loggedUser.isMaker && (
          <InteractiveTutorial
            showTutorial={!loggedUser.tutorial_state.manageStore}
            steps={MANAGE_STORE}
            tutorial={TUTORIAL_NAMES.MANAGE_STORE}
          />
        )}
    </Layout>
  )
}

Profile.propTypes = {
  location: PropTypes.object,
}

Profile.defaultProps = {
  location: {
    pathname: '',
    state: {
      collapsedListing: '',
      mylistings: false,
    },
  },
}

export default Profile
